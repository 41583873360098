<template>
  <v-content>
    <v-snackbar
      v-model="snackbar"
      color="success"
      :timeout="callbackResponse.timeout"
      top
    >
      {{ callbackResponse.message }}
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-dialog v-model="dialogEdit" max-width="500px">
      <editHouseForm
        v-if="dialogEdit"
        :editData="editedDataHouse"
        :success="success"
        @formResponse="onResponse"
      />
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <deleteListData
        :deleteFormData="deleteFormData"
        @formResponse="onResponseDelete"
      ></deleteListData>
    </v-dialog>
    <v-dialog v-model="dialogCreate" max-width="500px">
      <template v-slot:activator="{ on }">
        <v-btn
          color="primary"
          dark
          class="ma-4 float-right"
          v-on="on"
          :success="success"
          >{{ $t("add_house") }}</v-btn
        >
      </template>
      <createHouseForm :success="success" @formResponse="onResponse" />
    </v-dialog>
    <v-container fluid>
      <vue-good-table
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :isLoading.sync="isLoading"
        :pagination-options="{ enabled: true, perPageDropdown: [50, 100, 200] }"
        :rows="houseRows"
        :columns="columnsHouse"
      >
        <template slot="table-column" slot-scope="props">
          <span>
            {{ $t(props.column.label) }}
          </span>
        </template>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'">
            <v-icon small class="mr-2" @click="editItemHouse(props.row)"
              >edit</v-icon
            >
            <v-icon small @click="deleteItemHouse(props.row)">delete</v-icon>
          </span>
          <span v-else-if="props.column.field == 'imageurl'">
            <img :src="props.row.imageurl" height="130" width="250" />
          </span>
        </template>
      </vue-good-table>
    </v-container>
  </v-content>
</template>

<script>
import axios from "axios";
import createHouseForm from "./CreateHouse.vue";
import editHouseForm from "./EditHouse.vue";

import deleteListData from "@/components/deleteModal";
import { parseJwt } from "@/mixins/parseJwt";

export default {
  name: "HomestayHouseList",
  mixins: [parseJwt],
  components: {
    createHouseForm,
    editHouseForm,
    deleteListData,
  },
  data() {
    return {
      callbackResponse: {
        timeout: 6000,
      },
      snackbar: false,
      success: false,
      dialogCreate: false,
      dialogEdit: false,
      dialogDelete: false,
      panel: [0],
      deleteFormData: {},
      popout: true,
      isLoading: false,
      columnsHouse: [
        {
          label: "s_no",
          field: "countIndex",
        },

        {
          label: "house_name",
          field: "houseName",
        },
        {
          label: "house_owner_name",
          field: "houseOwner",
        },
        {
          label: "house_no",
          field: "houseNumber",
        },
        {
          label: "pax",
          field: "totalRoom",
        },
        {
          label: "image",
          field: "imageurl",
        },
        {
          label: "actions",
          field: "actions",
          sortable: false,
        },
      ],
      houseRows: [],
      totalRecords: 0,
      serverParamsHouse: {
        search: "",
        columnFilters: {},
        sort: [
          {
            field: "countIndex",
            type: "asc",
          },
        ],
        page: 1,
        perPage: 50,
      },

      user: [],
      editedIndex: -1,
      editedDataHouse: {},
    };
  },

  mounted() {
    this.loadItemsHouse();
    this.filterData();
  },
  destroyed() {
    console.log(this); // There's practically nothing here!
  },
  methods: {
    onResponse(data) {
      if (data) {
        this.dialogCreate = false;
        this.dialogEdit = false;
        this.dialogDelete = false;
        this.callbackResponse.message = data.message;
        this.$emit("formResponseRoom", data);
        this.loadItemsHouse();
      }
    },
    onResponseDelete(data) {
      console.log("ListHouseDelete", data);
      if (data) {
        this.$emit("formResponseHouseDelete", data);
        this.dialogDelete = false;
        this.loadItemsHouse();
      } else {
        this.dialogDelete = false;
      }
    },
    editItemHouse(props) {
      this.editedDataHouse = props;
      this.dialogEdit = true;
      // this.dialogEditFare = true;
    },

    deleteItemHouse(props) {
      console.log(props);
      this.dialogDelete = true;
      this.deleteFormData.url =
        "Hotel/DeleteHomestayHouseAsync/" + props.homestayHouseID;
      this.loadItemsHouse();
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedData = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    updateParams(newProps) {
      this.serverParamsHouse = Object.assign(
        {},
        this.serverParamsHouse,
        newProps
      );
    },

    searchInput() {
      this.loadItemsHouse();
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItemsHouse();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItemsHouse();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.loadItemsHouse();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItemsHouse();
    },
    async onDownload() {
      await axios({
        url: "Hotel/Download", //your url
        method: "POST",
        responseType: "blob",
        // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
      //.post("Hotel/Download");

      //       var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      //       console.log(response)
      //       var blob = new Blob([response.data], { type: contentType });
      //       console.log(blob);
      //var link=document.createElement('a');
      //             link.href=window.URL.createObjectURL(blob);
      //             link.download="Report.xlsx";
      //       link.click();

      //ExportListToExcel
    },

    async filterData() {},
    // load items is what brings back the rows from server
    async loadItemsHouse() {
      console.log(this.tokenData);
      // eslint-disable-next-line no-console
        let param = {
        PageSize: this.serverParamsHouse.perPage,
        PageNo: this.serverParamsHouse.page,
        OrderType: this.serverParamsHouse.sort[0].type,
        OrderBy: this.serverParamsHouse.sort[0].field,
      };

      console.log("paramchecker5", param);
      axios
        .post("Hotel/GetHomestayHouseListAsync", param)
        .then((response) => {
          this.houseRows = response.data.data;
          this.totalRecords = response.data.totalCount;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-item-group {
  background: #d2e3ff73;
}

.v-expansion-panel-header {
  background: #d2e3ff73;
}

button {
  z-index: 1;
}
</style>
